import { createSlice } from '@reduxjs/toolkit'

import * as helpers from './helpers'

const INITIAL_STATE = []

const apiCacheSlice = createSlice({
    name: 'apiCache',
    initialState: INITIAL_STATE,
    reducers: {
        saveApiCall(state, action) {
            const objectIdx = helpers.getObjectIndex(state, action.payload.url, 'url')
            if (objectIdx === -1) {
                state.push(action.payload)
            } else {
                state[objectIdx] = action.payload
            }
        },
        clearApiCall(state, action) {
            state.filter(s => s.url !== action.payload.url)
        }
    }
})

export const { saveApiCall, clearApiCall } = apiCacheSlice.actions
export default apiCacheSlice.reducer
