import React from 'react'
import { createBrowserHistory } from 'history'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

export const withRouterProps = (Component) => {
    const Wrapper = (props) => {
        const history = createBrowserHistory()
        const location = useLocation()
        const navigate = useNavigate()
        const params = useParams()
        return (
            <Component
                history={history}
                location={location}
                navigate={navigate}
                params={params}
                {...props}
            />
        )
    }
    return Wrapper
}
