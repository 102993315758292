import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { buildRoutePath, routes } from '../../constants/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const NavMain = () => {
    const [ isOpen, setIsOpen ] = useState(false)
    const renderMenuOverlay = () => {
        return (
            <div id="menu-main" className={`menu-overlay ${isOpen ? 'active' : ''}`}>
                <div className="closer" onClick={() => setIsOpen(false)}>
                    <FontAwesomeIcon icon="times" />
                </div>
                <div className="menu">
                    <div className="links">
                        <Link onClick={() => setIsOpen(false)} to={routes.gameIndex.path}>Games</Link>
                        <Link onClick={() => setIsOpen(false)} to={buildRoutePath(routes.joinGame.path, { gameId: null })}>Join Game</Link>
                        <Link onClick={() => setIsOpen(false)} to={routes.howTo.path}>How To Play</Link>
                        <Link onClick={() => setIsOpen(false)} to={routes.about.path}>About</Link>
                        <Link onClick={() => setIsOpen(false)} to={routes.authLogout.path}>Logout</Link>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div id="nav-main" onClick={() => setIsOpen(!isOpen)}>
                <FontAwesomeIcon icon="bars" />
            </div>
            { renderMenuOverlay() }
        </>
    )
}

export default NavMain
