import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withRouterProps } from '../utils/with-router-props'

import NavHeader from '../nav/nav-header'

import { routes } from '../../constants/routes'
import { registerEmailAndPassAndLink, registerWithEmailAndPass } from '../../actions/auth'
import { upsertForm } from '../../reducers/form'
import { FORM_STATES } from '../../constants/helper-states'

import shortid from 'shortid'

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        forms: state.forms
    }
}

const EmailRegister = ({ dispatch, navigate, auth, forms, fireauth }) => {
    const [formId] = useState(shortid.generate())
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const activeForm = forms.find(f => f.id === formId)

    useEffect(() => {
        dispatch(upsertForm({ id: formId, status: FORM_STATES.READY }))
    }, [dispatch, formId])

    useEffect(() => {
        if (activeForm && activeForm.status === FORM_STATES.SUCCESS) {
            navigate(routes.router.path)
        }
    }, [dispatch, activeForm, formId, navigate])

    const handleSubmit = (evt) => {
        evt.preventDefault()
        if (auth.authId) {
            dispatch(registerEmailAndPassAndLink(fireauth, email, password, formId))
        } else {
            dispatch(registerWithEmailAndPass(fireauth, email, password, formId))
        }
    }

    return (
        <div className="public-page">
            <div className="setup-pages">
                <NavHeader />
                <div className="content">
                    <h1 className="txt-center">Register</h1>
                    <p>Registration is free and allows you to play the game from multiple devices.</p>
                    { auth.authId ? <p className="txt-center">You have a session.</p> : <p>no session</p>}
                    <form onSubmit={handleSubmit}>
                        <div className="input-wrapper">
                            <label>Email<span className="required">*</span></label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(evt) => setEmail(evt.target.value)}
                                placeholder="Enter your email"
                                required />
                        </div>
                        <div className="input-wrapper">
                            <label>Password<span className="required">*</span></label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={password}
                                onChange={(evt) => setPassword(evt.target.value)}
                                placeholder="Enter your password"
                                required />
                        </div>
                        <div className="input-wrapper">
                            <button type="submit" className="btn submit">Submit</button>
                        </div>
                        { activeForm && activeForm.status === FORM_STATES.ERROR ? <p className="txt-center error">{activeForm.body}</p> : null }
                    </form>
                    <p className="txt-center">
                        Have an account? <Link to={routes.authLogin.path}>Login</Link>.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(EmailRegister))
