import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { routes, nestedRoutePath } from '../../constants/routes'

import Welcome from './welcome'
import CreateGame from './create'
import JoinGame from './join'
import PlayerName from './player-name'
import SetupGame from '../game/play/setup'
import About from './about'
import HowTo from './how-to'

const PublicIndex = ({ fireauth }) => {
    return (
        <div className="public-page">
            <Routes>
                <Route path={nestedRoutePath(routes.publicIndex.path, routes.createGame.path)} element={<CreateGame fireauth={fireauth} />} />
                <Route path={nestedRoutePath(routes.publicIndex.path, routes.joinGame.path)} element={<JoinGame fireauth={fireauth} />} />
                <Route path={nestedRoutePath(routes.publicIndex.path, routes.setupGame.path)} element={<SetupGame />} />
                <Route path={nestedRoutePath(routes.publicIndex.path, routes.playerName.path)} element={<PlayerName />} />
                <Route path={nestedRoutePath(routes.publicIndex.path, routes.about.path)} element={<About />} />
                <Route path={nestedRoutePath(routes.publicIndex.path, routes.howTo.path)} element={<HowTo />} />
                <Route index element={<Welcome />} />
            </Routes>
        </div>
    )
}

export default PublicIndex
