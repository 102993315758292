import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../constants/routes'
import { withRouterProps } from '../utils/with-router-props'
import { anonymousSignIn } from '../../actions/auth'
import { callApi } from '../../actions/api'
import { FORM_STATES } from '../../constants/helper-states'
import { upsertForm } from '../../reducers/form'
import shortid from 'shortid'
import NavHeader from '../nav/nav-header'

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        forms: state.forms,
        games: state.games
    }
}

const JoinGame = ({ dispatch, navigate, auth, fireauth, forms, games, params }) => {
    const [screenName, setScreenName] = useState('')
    const [code, setCode] = useState(params?.gameId || '')
    const [submitted, setSubmitted] = useState(false)
    const [formId] = useState(shortid.generate())
    const activeForm = forms.find(f => f.id === formId)

    useEffect(() => {
        dispatch(upsertForm({ id: formId, status: FORM_STATES.READY }))
    }, [dispatch, formId])

    useEffect(() => {
        if (activeForm && activeForm.status === FORM_STATES.SUCCESS) {
            const game = games.find(g => g.id === activeForm.body)
            let gamePath = routes.gameIndex.path
            if (game) {
                gamePath = buildRoutePath(routes.setupGame.path, { gameId: game.code })
            }
            navigate(gamePath, { state: { from: { pathname: gamePath } } })
        }
    }, [dispatch, activeForm, formId, navigate, games])

    useEffect(() => {
        if (activeForm && activeForm.status === FORM_STATES.SUCCESS) {
            const gamePath = buildRoutePath(routes.gameView.path, { gameId: code })
            navigate(gamePath, { state: { from: { pathname: gamePath } } })
        }
    }, [dispatch, activeForm, navigate, code])

    const handleSubmit = (evt) => {
        evt.preventDefault()
        if (!code || code.trim().length !== 8 || !screenName || screenName.trim() === '') {
            alert('Please fill in all fields')
            return
        }
        dispatch(upsertForm({ id: formId, status: FORM_STATES.PROCESSING }))
        if (!auth || !auth.authId) {
            dispatch(anonymousSignIn(fireauth))
        }
        setSubmitted(true)
    }

    useEffect(() => {
        if (auth && auth.authId && submitted && code.trim().length === 8 && screenName.trim() !== '') {
            const payload = {
                screenName,
                code
            }
            dispatch(callApi(apiResourceEndpoint('games', 'CREATE', null, 'join'), payload, formId))
            setSubmitted(false)
        }
    }, [dispatch, formId, auth, screenName, code, submitted])

    return (
        <div className="setup-pages">
            <NavHeader />
            <div className="content">
                <h1 className="txt-center mb-2">Join a Game</h1>
                <form onSubmit={handleSubmit}>
                    <div className="input-wrapper">
                        <label>What would you like to be called?<span className="required">*</span></label>
                        <input
                            type="text"
                            id="screenname"
                            name="screenname"
                            value={screenName}
                            onChange={(evt) => setScreenName(evt.target.value)}
                            placeholder="Your name that other players will see in the game" />
                    </div>
                    <div className="input-wrapper">
                        <label>Game Code<span className="required">*</span></label>
                        <input
                            type="text"
                            id="code"
                            name="code"
                            value={code}
                            onChange={(evt) => setCode(evt.target.value)}
                            placeholder="Enter code" />
                    </div>
                </form>
            </div>
            <div className="action">
                <span className="btn submit" onClick={handleSubmit}>
                    next
                </span>
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(withRouterProps(JoinGame))
