import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { buildRoutePath, routes } from '../../constants/routes'

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

const Welcome = ({ auth }) => {
    return (
        <div className="welcome-page">
            <img src="/assets/img/shady-bingo-logo.svg" alt="Shady Bingo Logo" />
            <Link to={routes.createGame.path} className="btn black">
                Create Game
            </Link>
            <Link to={buildRoutePath(routes.joinGame.path, { gameId: null })} className="btn black">
                Join Game
            </Link>
            <Link to={routes.gameIndex.path} className="btn black" style={{ visibility: `${auth?.authId ? 'visible' : 'hidden'}` }}>
                My Games
            </Link>
            <Link to={routes.authLogin.path} className="btn text-only">
                login or register
            </Link>
            <div className="made-by">
                <span>Made by</span>
                <div className="logo-wrapper">
                    <a href="https://www.shadyhillstudios.com">
                        <img src="/assets/img/shs-logo-black.png" alt="Shady Hill Studios" width={100} />
                    </a>
                </div>
                <a className="shs" href="https://www.shadyhillstudios.com">Shady Hill Studios</a>
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(Welcome)
