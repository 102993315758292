import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../utils/with-router-props'
import shortid from 'shortid'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../constants/routes'
import { anonymousSignIn } from '../../actions/auth'
import { upsertForm } from '../../reducers/form'
import { FORM_STATES } from '../../constants/helper-states'
import { callApi } from '../../actions/api'
import NavHeader from '../nav/nav-header'

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        forms: state.forms,
        games: state.games
    }
}

const CreateGame = ({ dispatch, navigate, auth, fireauth, forms, games }) => {
    const [ screenName, setScreenName ] = useState('')
    const [ gameName, setGameName ] = useState('')
    const [ description, setDescription ] = useState('')
    const [ submitted, setSubmitted ] = useState(false)
    const [formId] = useState(shortid.generate())
    const activeForm = forms.find(f => f.id === formId)

    useEffect(() => {
        dispatch(upsertForm({ id: formId, status: FORM_STATES.READY }))
    }, [dispatch, formId])

    useEffect(() => {
        if (activeForm && activeForm.status === FORM_STATES.SUCCESS) {
            const game = games.find(g => g.id === activeForm.body)
            let gamePath = routes.gameIndex.path
            if (game) {
                gamePath = buildRoutePath(routes.setupGame.path, { gameId: game.code })
            }
            navigate(gamePath, { state: { from: { pathname: gamePath } } })
        }
    }, [dispatch, activeForm, formId, navigate, games])

    const handleSubmit = (evt) => {
        evt.preventDefault()
        if (!screenName || !gameName) {
            setSubmitted(false)
            alert('Please fill in all required fields')
            return
        }
        dispatch(upsertForm({ id: formId, status: FORM_STATES.PROCESSING }))
        if (!auth || !auth.authId) {
            dispatch(anonymousSignIn(fireauth))
        }
        setSubmitted(true)
    }

    useEffect(() => {
        if (auth && auth.authId && submitted && screenName !== '' && gameName !== '') {
            const payload = {
                screenName,
                gameName,
                description
            }
            dispatch(callApi(apiResourceEndpoint('games', 'CREATE'), payload, formId))
            setSubmitted(false)
        }
    }, [dispatch, formId, auth, submitted, screenName, gameName, description])

    return (
        <div className="setup-pages">
            <NavHeader />
            <div className="content">
                <h1 className="txt-center mb-2">Create Game</h1>
                <form onSubmit={handleSubmit} disabled={activeForm && (activeForm.status === 'PROCESSING' || activeForm.status === 'SUCCESS') ? 'disabled' : null}>
                    <div className="input-wrapper">
                        <label>What would you like to be called?<span className="required">*</span></label>
                        <input
                            type="text"
                            id="screenname"
                            name="screenname"
                            value={screenName}
                            onChange={(evt) => setScreenName(evt.target.value)}
                            placeholder="Enter a screen name for your game"
                            required />
                    </div>
                    <div className="input-wrapper">
                        <label>Game Name<span className="required">*</span></label>
                        <input
                            type="text"
                            id="gameName"
                            name="gameName"
                            value={gameName}
                            onChange={(evt) => setGameName(evt.target.value)}
                            placeholder="Enter a name for your game"
                            required />
                    </div>
                    <div className="input-wrapper">
                        <label>Description</label>
                        <textarea
                            rows="7"
                            id="description"
                            name="description"
                            value={description}
                            onChange={(evt) => setDescription(evt.target.value)}
                            placeholder="Enter a description for your game" />
                    </div>
                    <div style={{ display: 'none' }}>
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>
            <div className="action">
                <button className="btn submit" disabled={activeForm && (activeForm.status === 'PROCESSING' || activeForm.status === 'SUCCESS') ? 'disabled' : null} onClick={handleSubmit}>
                    proceed
                </button>
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(CreateGame))
