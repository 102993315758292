import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import NavHeader from '../nav/nav-header'
import shortid from 'shortid'
import { FORM_STATES } from '../../constants/helper-states'
import { upsertForm } from '../../reducers/form'
import { generatePasswordResetLink } from '../../actions/auth'
import { Link } from 'react-router-dom'
import { routes } from '../../constants/routes'

const mapStateToProps = (state) => {
    return {
        forms: state.forms
    }
}

const PasswordHelp = ({ dispatch, fireauth, forms }) => {
    const [emailSent, setEmailSent] = useState(false)
    const [formId] = useState(shortid.generate())
    const [email, setEmail] = useState('')
    const activeForm = forms.find(f => f.id === formId)
    const activeFormStatus = activeForm?.status

    useEffect(() => {
        dispatch(upsertForm({ id: formId, status: FORM_STATES.READY }))
    }, [dispatch, formId])

    useEffect(() => {
        if (activeFormStatus === FORM_STATES.SUCCESS) {
            setEmailSent(true)
        }
    }, [dispatch, activeFormStatus, formId])

    const handleSubmit = (evt) => {
        evt.preventDefault()
        dispatch(generatePasswordResetLink(fireauth, email, formId))
    }

    return (
        <div className="public-page">
            <div className="setup-pages">
                <NavHeader />
                <div className="content">
                    <h1 className="txt-center">Password Help</h1>
                    { emailSent
                        ? <><p className="txt-center">An email has been sent to {email} with a link to reset your password.</p><p className="txt-center"><Link to={routes.authLogin.path}>Return to Login</Link></p></>
                        : (
                            <form onSubmit={handleSubmit}>
                                <div className="input-wrapper">
                                    <label>Email<span className="required">*</span></label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={(evt) => setEmail(evt.target.value)}
                                        placeholder="Enter your email"
                                        required />
                                </div>
                                <div className="input-wrapper">
                                    <button type="submit" className="btn submit">Submit</button>
                                </div>
                                {activeForm && activeForm.status === FORM_STATES.ERROR ? <p className="txt-center error">{activeForm.body}</p> : null}
                            </form>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(PasswordHelp)
