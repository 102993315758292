import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { withRouterProps } from '../../utils/with-router-props'
import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'
// import GameMenu from './game-menu'
import GameBoard from './game-board'
import ItemList from './item-list'
import NavHeader from '../../nav/nav-header'

const selectGames = state => state.games
const selectGameCode = (state, gameCode) => gameCode
const selectEntries = state => state.entries
const selectUser = state => state.user
const selectPlayers = state => state.players

const makeGame = createSelector(
    [selectGames, selectGameCode],
    (games, gameCode) => games.find(game => game.code === gameCode)
)

const makeGameEntries = createSelector(
    [selectEntries, selectGames, selectGameCode],
    (entries, games, gameCode) => {
        const game = games.find(g => g.code === gameCode)
        return entries.filter(entry => entry.gameId === game.id)
            .sort((a, b) => {
                if (a.displayOrder > b.displayOrder) {
                    return 1
                }
                return -1
            })
    }
)

const makeGamePlayer = createSelector(
    [selectPlayers, selectUser, selectGames, selectGameCode],
    (players, user, games, gameCode) => {
        const game = games.find(g => g.code === gameCode)
        return players.find(player => player.userId === user.id && player.gameId === game.id)
    }
)

const mapStateToProps = (state, ownProps) => {
    return {
        game: makeGame(state, ownProps.params.gameId),
        entries: makeGameEntries(state, ownProps.params.gameId),
        player: makeGamePlayer(state, ownProps.params.gameId)
    }
}

const ViewGame = ({ dispatch, fireauth, params, navigate, game, entries, player }) => {
    const [ activeEntryId, setActiveEntryId ] = useState(null)
    const gameCode = params.gameId

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('games', 'VIEW', gameCode)))
    }, [dispatch, gameCode])

    useEffect(() => {
        if (activeEntryId) {
            let entry = document.getElementById(`entry-${activeEntryId}`)
            entry.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }, [activeEntryId])

    if (!game) {
        return <div>Loading game....</div>
    }

    if (game && game.status === 'SETUP') {
        return navigate(buildRoutePath(routes.setupGame.path, { gameId: gameCode }))
    }

    return (
        <div className="game-page">
            <NavHeader peopleIcon={true} />
            <GameBoard player={player} entries={entries} activeEntryId={activeEntryId} onSetActiveEntryId={setActiveEntryId} />
            <ItemList entries={entries} player={player} game={game} activeEntryId={activeEntryId} onSetActiveEntryId={setActiveEntryId} />
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(ViewGame))
