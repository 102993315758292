import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { Route, Routes } from 'react-router-dom'
import { withRouterProps } from '../../utils/with-router-props'

import { nestedRoutePath, routes } from '../../../constants/routes'

import ViewGame from './view'
import SetupGame from './setup'

import Pusher from 'pusher-js'
import { connectToPusherWebsocket } from '../../../actions/auth'
import Echo from 'laravel-echo'
import { apiRemoveAction, manageApiData } from '../../../actions/api'
window.Pusher = Pusher

const selectGames = state => state.games
const selectGameCode = (state, gameCode) => gameCode
const selectPlayers = state => state.players
const selectGameId = (state, game) => game?.id
const selectUser = state => state.user

const makeGame = createSelector(
    [selectGames, selectGameCode],
    (games, gameCode) => games.find(game => game.code === gameCode)
)

const makeMyPlayer = createSelector(
    [selectPlayers, selectUser, selectGameId],
    (players, user, gameId) => players.find(player => player.userId === user.id && player.gameId === gameId)
)

const mapStateToProps = (state, ownProps) => {
    const gameCode = ownProps.params.gameId
    const game = makeGame(state, gameCode)
    const myPlayer = makeMyPlayer(state, game)
    return { game, myPlayer }
}

const GamePlayIndex = ({ dispatch, fireauth, game, myPlayer }) => {
    const gameCode = game?.code
    const playerCode = myPlayer?.code

    useEffect(() => {
        const fetchAuthToken = async () => {
            let authToken = await connectToPusherWebsocket(fireauth)
            window.Echo = new Echo({
                broadcaster: 'reverb',
                key: process.env.REACT_APP_REVERB_APP_KEY,
                wsHost: process.env.REACT_APP_REVERB_HOST,
                wsPort: process.env.REACT_APP_REVERB_PORT,
                wsPath: '/ws',
                authEndpoint: `${process.env.REACT_APP_API_URL}/reverb-broadcasting/`,
                auth: { headers: authToken },
                wssPort: process.env.REACT_APP_REVERB_SSL_PORT ?? 443,
                forceTLS: (process.env.REACT_APP_REVERB_SCHEME ?? 'https') === 'https',
                enabledTransports: ['ws', 'wss']
            })

            window.Echo.private(`game-${gameCode}`)
                .listen('.game-ws-payload', async (data) => {
                    dispatch(manageApiData(data))
                })
                .listen('.game-ws-remove-item', async (data) => {
                    dispatch(apiRemoveAction(data))
                })
            window.Echo.private(`game-${gameCode}-${playerCode}`)
                .listen('.player-ws-payload', async (data) => {
                    dispatch(manageApiData(data))
                })
                .listen('.player-ws-remove-item', async (data) => {
                    dispatch(apiRemoveAction(data))
                })

            window.Echo.connector.pusher.connection.bind('connected', (data) => {})
            window.Echo.connector.pusher.connection.bind('connecting', async (data) => {
                authToken = await connectToPusherWebsocket(fireauth)
                window.Echo.options.auth = { headers: authToken }
            })
            window.Echo.connector.pusher.connection.bind('unavailable', (data) => {})
            window.Echo.connector.pusher.connection.bind('failed', (data) => {})
            window.Echo.connector.pusher.connection.bind('disconnected', async (data) => {
                alert('session timed out, refresh the page to continue')
            })
        }
        if (gameCode && playerCode && fireauth) {
            fetchAuthToken()
        }

        return () => {
            if (window?.Echo) {
                window?.Echo.leave(`game-${gameCode}`)
                window?.Echo.leave(`game-${gameCode}-${playerCode}`)
            }
        }
    }, [dispatch, fireauth, gameCode, playerCode])

    return (
        <Routes>
            <Route path={nestedRoutePath(routes.gameView.path, routes.setupGame.path)} element={<SetupGame />} />
            <Route index element={<ViewGame />} />
        </Routes>
    )
}

export default withRouterProps(connect(mapStateToProps)(GamePlayIndex))
