import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'

import { withRouterProps } from '../utils/with-router-props'
import { buildRoutePath, routes } from '../../constants/routes'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const selectGames = state => state.games
const selectGameId = (state, gameId) => gameId
const selectPlayers = state => state.players
const selectGame = (state, game) => game

const makeGame = createSelector(
    [selectGames, selectGameId],
    (games, gameId) => games.find(g => g.code === gameId)
)

const makeGamePlayers = createSelector(
    [selectPlayers, selectGame],
    (players, game) => players.filter(p => p.gameId === game.id)
)

const mapsStateToProps = (state, ownProps) => {
    const game = makeGame(state, ownProps.params.gameId)
    return {
        game,
        players: makeGamePlayers(state, game),
        onlinePlayers: state.onlinePlayers.find(op => op.gameCode === ownProps.params.gameId)
    }
}

const NavPeople = ({ game, players, onlinePlayers }) => {
    const [ isOpen, setIsOpen ] = useState(false)
    const [ showCopied, setShowCopied ] = useState(false)
    const copyGameCode = () => {
        if (!showCopied) {
            setShowCopied(true)
        }
        const joinCodePath = buildRoutePath(routes.joinGame.path, { gameId: game.code })
        navigator.clipboard.writeText(process.env.REACT_APP_WEBAPP_URL + joinCodePath)
    }

    useEffect(() => {
        if (showCopied) {
            const timeout = setTimeout(() => {
                setShowCopied(false)
            }, 5000)
            return () => clearTimeout(timeout)
        }
    }, [showCopied])

    const renderPlayers = () => {
        let allPlayers = []
        players.forEach(player => {
            let userIcon = ['far', 'user']
            if (onlinePlayers && onlinePlayers.players.includes(player.code)) {
                userIcon = 'user'
            }
            allPlayers.push(
                <div key={player.id}>
                    <div className="user">
                        <span>{player.screenName}</span>
                        <FontAwesomeIcon icon={userIcon} />
                    </div>
                </div>
            )
        })
        if (allPlayers.length > 0) {
            <div className="no-players">
                <span>No players yet</span>
            </div>
        }
        // TODO: wrap this in a scrollable container
        return allPlayers
    }

    const renderMenuOverlay = () => {
        return (
            <div id="menu-people" className={`menu-overlay ${isOpen ? 'active' : ''}`}>
                <div className="closer" onClick={() => setIsOpen(false)}>
                    <FontAwesomeIcon icon="times" />
                </div>
                <div className="menu">
                    <div className="menu-wrapper">
                        <div className="share-instructions">
                            <p>
                                Share the code below with your friends to invite them to your game!<br/>
                                Or click the link and paste it into an email or text.
                            </p>
                        </div>
                        <div className="game-code">
                            {game ?
                                <span>{game.code}</span>
                                : null
                            }
                        </div>
                        <div className={`btn ${showCopied ? `blue` : `black`}`} onClick={() => {
                            copyGameCode()
                        }}>
                            { showCopied && <span>Copied to Clipboard</span> }
                            { !showCopied && <span>Copy / Share Link</span> }
                        </div>
                        <div className="user-list">
                            <label>
                                Players
                            </label>
                            {renderPlayers()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div id="nav-people" onClick={() => setIsOpen(!isOpen)}>
                <FontAwesomeIcon icon="user" />
                {players.length > 0 ?
                    <div className="counter">{players.length}</div>
                    : null}
            </div>
            { renderMenuOverlay() }
        </>
    )
}

export default withRouterProps(connect(mapsStateToProps)(NavPeople))
