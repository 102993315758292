import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouterProps } from '../utils/with-router-props'
import { handleSignOut } from '../../actions/auth'
import { routes } from '../../constants/routes'

const Logout = ({ dispatch, fireauth }) => {
    const [readyToRoute, setReadyToRoute] = useState(false)
    const hasFireAuth = fireauth ? true : false
    useEffect(() => {
        if (hasFireAuth) {
            dispatch(handleSignOut(fireauth))
            if (!readyToRoute) {
                setReadyToRoute(true)
            }
        }
    }, [dispatch, readyToRoute, hasFireAuth, fireauth])

    if (readyToRoute) {
        return <Navigate to={routes.publicIndex.path} />
    }

    return (
        <div>Logging out...</div>
    )
}

export default withRouterProps(connect()(Logout))
