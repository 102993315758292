import React from 'react'
import NavPeople from './nav-people'
import NavMain from './nav-main'

const NavHeader = ({ peopleIcon }) => {
    return (
        <div className="header-area">
            { peopleIcon && <NavPeople />}
            <div id="header-logo">
                <img src="/assets/img/shady-bingo-logo.svg" alt="Shady Bingo Logo" width="100%" />
            </div>
            <NavMain />
        </div>
    )
}

export default NavHeader
