import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withRouterProps } from '../utils/with-router-props'

import NavHeader from '../nav/nav-header'
import { routes } from '../../constants/routes'
import { upsertForm } from '../../reducers/form'
import { FORM_STATES } from '../../constants/helper-states'
import { signInWithEmailAndPass } from '../../actions/auth'

import shortid from 'shortid'

const mapStateToProps = (state) => {
    return {
        forms: state.forms
    }
}

const Login = ({ dispatch, navigate, forms, fireauth }) => {
    const [formId] = useState(shortid.generate())
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const activeForm = forms?.find(f => f.id === formId)
    const activeFormStatus = activeForm?.status

    useEffect(() => {
        dispatch(upsertForm({ id: formId, status: FORM_STATES.READY }))
    }, [dispatch, formId])

    useEffect(() => {
        console.log('in the effect')
        console.log(activeFormStatus)
        if (activeFormStatus === FORM_STATES.SUCCESS) {
            navigate(routes.router.path)
        }
    }, [dispatch, activeFormStatus, formId, navigate])

    const handleSubmit = (evt) => {
        evt.preventDefault()
        dispatch(signInWithEmailAndPass(fireauth, email, password, formId))
    }

    return (
        <div className="public-page">
            <div className="setup-pages">
                <NavHeader />
                <div className="content">
                    <h1 className="txt-center">Login</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="input-wrapper">
                            <label>Email<span className="required">*</span></label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(evt) => setEmail(evt.target.value)}
                                placeholder="Enter your email"
                                required />
                        </div>
                        <div className="input-wrapper">
                            <label>Password<span className="required">*</span></label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={password}
                                onChange={(evt) => setPassword(evt.target.value)}
                                placeholder="Enter your password"
                                required />
                        </div>
                        <div className="input-wrapper">
                            <button type="submit" className="btn submit">Submit</button>
                        </div>
                        {activeForm && activeForm.status === FORM_STATES.ERROR ? <p className="txt-center error">{activeForm.body}</p> : null}
                    </form>
                    <p className="txt-center">
                        No account? <Link to={routes.authRegister.path}>Register here</Link>.
                    </p>
                    <p className="txt-center">
                        <Link to={routes.authPasswordHelp.path}>Forgot password?</Link>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(Login))
