import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

import { FB_CONFIG } from './config'

let fireauth

const initFirebase = () => {
    const app = initializeApp(FB_CONFIG)
    fireauth = getAuth(app)
}

const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
        {
            // provider: fireauth.GoogleAuthProvider.PROVIDER_ID,
            customParameters: {
            }
        }
    ]
}

const actionCodeSettings = {
    url: 'http://localhost:3000/password-reset/',
    handleCodeInApp: true
}

export {
    initFirebase,
    fireauth,
    uiConfig,
    actionCodeSettings
}
