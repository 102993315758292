export const FETCHING_STATES = {
    EMPTY: '',
    FETCHING: 'FETCHING',
    ERROR: 'ERROR',
    COMPLETE: 'COMPLETE'
}

export const FORM_STATES = {
    EMPTY: '',
    READY: 'READY',
    PROCESSING: 'PROCESSING',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS'
}
