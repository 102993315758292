import { configureStore } from '@reduxjs/toolkit'
import bingoData from '../reducers/index'

export default function initStore(initialState) {
    const store = configureStore({
        reducer: bingoData,
        preloadedState: initialState,
        devTools: false
    })

    return store
}
