import React from 'react'

import EntryItem from './entry-item'

const ItemList = ({ entries, game, player, activeEntryId, onSetActiveEntryId }) => {
    const handleSelectTile = (entryId) => {
        onSetActiveEntryId(entryId)
    }

    const renderEntries = () => {
        let allEntries = []
        entries.forEach(entry => {
            let onBoard = false
            if (player._computed?.boardEntries) {
                onBoard = player._computed.boardEntries.find(b => {
                    return b === entry.id
                })
            }
            allEntries.push(
                <EntryItem
                    key={entry.id}
                    entry={entry}
                    game={game}
                    onBoard={onBoard}
                    player={player}
                    activeEntryId={activeEntryId}
                    onHandleSelectTile={handleSelectTile}
                />
            )
        })
        return allEntries
    }

    return (
        <div className="entries-wrapper">
            <div className="entries-list">
                {renderEntries()}
            </div>
        </div>
    )
}

export default ItemList
