import React from 'react'
import { connect } from 'react-redux'
import NavHeader from '../nav/nav-header'

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

const About = ({ auth }) => {
    return (
        <div className="setup-pages">
            <NavHeader />
            <div className="content">
                <h1 className="txt-center mb-2">About</h1>
                <p>Shady Bingo is a web application that allows you to play a custom Bingo game with your family and friends online.</p>
                <p>It was created by <a href="https://www.shadyhillstudios.com" target="_blank" rel="noreferrer">Shady Hill Studios</a> as a way to lighten up anxiety fueled holidays and gatherings.</p>
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(About)
