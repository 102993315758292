import React from 'react'
import { connect } from 'react-redux'
import NavHeader from '../nav/nav-header'

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

const HowTo = ({ auth }) => {
    return (
        <div className="setup-pages">
            <NavHeader />
            <div className="content">
                <h1 className="txt-center mb-2">How To Play</h1>
                <p>In Shady Bingo, a game creator can set up a new bingo game by selecting a theme, naming the game, and inviting friends or family to join using a unique code. All players collaborate to customize the bingo board, which includes at least 24 squares plus a "free" square in the center. Each square represents an event or action related to the chosen theme.</p>
                <p>As the game progresses, players mark off events that happen in real life—clicking a square on desktop or tapping it on mobile. The first player to mark five squares in a row (horizontally, vertically, or diagonally) wins the game!</p>
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(HowTo)
