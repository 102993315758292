import React, { useState, useEffect } from 'react'

const GameTile = ({ gameEntry, bottomBorder, rightBorder, activeEntryId, onHandleSelectTile, onHandleMarkTile }) => {
    const [marked, setMarked] = useState(gameEntry.isMarked)
    const [selected, setSelected] = useState(gameEntry.id === activeEntryId)
    const handleTouchEnd = () => {
        if (!selected) {
            onHandleSelectTile(gameEntry.id)
        } else if (!marked) {
            onHandleMarkTile(gameEntry)
        } else {
            onHandleMarkTile(gameEntry)
        }
    }

    useEffect(() => {
        setSelected(gameEntry.id === activeEntryId)
    }, [activeEntryId, gameEntry.id])

    useEffect(() => {
        setMarked(gameEntry.isMarked)
    }, [gameEntry.isMarked])

    return (
        <div
            className={`tile ${rightBorder} ${bottomBorder} ${selected ? 'selected' : ''} ${marked ? 'marked' : ''}`}
            onClick={handleTouchEnd}>
            <span>{gameEntry.displayOrder}</span>
        </div>
    )
}

export default GameTile
