import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { nestedRoutePath, routes } from '../../constants/routes'

import GamePlayIndex from './play/index'
import ListGames from './list'
// import SetupGame from './setup'

const GamesIndex = ({ fireauth }) => {
    return (
        <Routes>
            <Route path={nestedRoutePath(routes.gameIndex.path, routes.gameView.path, true)} element={<GamePlayIndex fireauth={fireauth} />} />
            <Route index element={<ListGames />} />
        </Routes>
    )
}

export default GamesIndex
