import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { buildRoutePath, routes } from '../../constants/routes'
import { withRouterProps } from '../utils/with-router-props'

const PlayerName = ({ navigate }) => {
    const [name, setName] = useState('')

    const handleSubmit = () => {
        if (!name) {
            alert('Please fill in all fields')
            return
        }
        navigate(buildRoutePath(routes.gameView.path, { gameId: 'testing' }))
    }

    return (
        <div className="setup-pages">
            <div className="header-area">
                <Link to={routes.publicIndex.path}>
                    <img src="/assets/img/shady-bingo-logo.svg" alt="Shady Bingo Logo" width="100%" />
                </Link>
            </div>
            <div className="content">
                <h1 className="txt-center mb-2">Join a Game</h1>
                <div className="input-wrapper">
                    <label>Your Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onChange={(evt) => setName(evt.target.value)}
                        placeholder="Enter a name" />
                </div>
            </div>
            <div className="action">
                <span className="btn submit" onClick={handleSubmit}>
                    next
                </span>
            </div>
        </div>
    )
}

export default withRouterProps(PlayerName)
